import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";

const Wrapper = styled.div`
  background: linear-gradient(to left, #1E5BB5, #03D4FF);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Open Sans";
  color: #fff;
  padding: 80px 0;
  margin-top: 80px;
  @media screen and (max-width: 991px) {
    padding: 60px 0;
  }
  @media screen and (max-width: 768px) {
    margin-top: 60px;
  }
  @media screen and (max-width: 576px) {
    margin-top: 40px;
  }
`;

const Title = styled.h3`
  font-size: 30px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  margin-bottom: 60px;
  color: #fff;
  @media screen and (max-width: 991px) {
    font-size: 26px;
  }
  @media screen and (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 30px;
  }
`;

const Card = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media screen and (max-width: 768px) {
    border: 0;
    margin: 30px 0;
  }
  @media screen and (max-width: 576px) {
    border: 0;
    margin: 15px 0;
  }
`;

const MiddleCard = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  align-items: center;
  @media screen and (max-width: 768px) {
    border: 0;
    margin: 30px 0;
  }
  @media screen and (max-width: 576px) {
    border: 0;
    margin: 15px 0;
  }
`;

const StyledNumber = styled.span`
  font-size: 40px;
  font-weight: 600;
  padding-bottom: 20px;
  color: #ebf6fb;
  @media screen and (max-width: 991px) {
    font-size: 36px;
  }
  @media screen and (max-width: 768px) {
    font-size: 30px;
    padding-bottom: 10px;
  }
`;

const StyledText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #ebf6fb;
  font-weight: 300;
  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }
`;

export default () => (
  <Wrapper>
    <Container>
      <Row className="justify-content-md-center">
        <Col xl={10} lg={11} md={12}>
        <ScrollAnimation animateOnce animateIn="fadeInDown" offset={0}>
          <Title>Our Achievements</Title>
          </ScrollAnimation>
            <Container>
              <Row>
                <Col md={4}>
                <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                  <Card>
                    <StyledNumber>1st</StyledNumber>
                    <StyledText>
                      To put an entire airframe onto blockchain
                    </StyledText>
                  </Card>
                  </ScrollAnimation>
                </Col>
                <Col md={4}>
                <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                  <MiddleCard>
                    <StyledNumber>1st</StyledNumber>
                    <StyledText>
                      To track all events, serialised parts and aircraft
                      movements using blockchain
                    </StyledText>
                  </MiddleCard>
                  </ScrollAnimation>
                </Col>
                <Col md={4}>
                <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
                  <Card>
                    <StyledNumber>1m+</StyledNumber>
                    <StyledText>Flight hours on our platform</StyledText>
                  </Card>
                  </ScrollAnimation>
                </Col>
              </Row>
            </Container>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);
