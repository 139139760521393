import React, { useState } from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import ModalVideo from "react-modal-video";
import playIcon from "../assets/Landing/play-icon.svg";
import Img from "gatsby-image";
import PageHeader from "../components/pageHeader";
import WeBelieve from "../components/About/weBelieve";
import Achievements from "../components/About/achievements";
import AboutGrid from "../components/About/aboutGrid";
import Started from "../components/Layout/started";
import pageStyles from "./page.module.less";
import SEO from "../components/SEO";
import ScrollAnimation from "react-animate-on-scroll";
import styles from "./about.module.less";
import GetInTouchBar from "../components/LandingPage/getInTouchBar";

const Title = styled.span`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "30px")};
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
  margin-bottom: 15px;
  @media screen and (max-width: 1200px) {
    font-size: 26px;
  }
  @media screen and (max-width: 991px) {
    font-size: 24px;
  }
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.88;
  letter-spacing: 0.02px;
  color: rgba(36, 45, 65, 0.7);
  padding: ${({ padding }) => (padding ? `${padding}` : "20px 0")};
`;

export default (props) => {
  const {
    videoImage,
    ourTechnologyMarkdown,
    ourVisionMarkdown,
    ourRoleMarkdown,
  } = props.data;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Layout>
      <SEO
        title="Transforming the aviation industry"
        description="We are working hard to digitise the aviation industry providing new levels of efficiency and insight to everyone involved in the operation and maintenance of aircraft. We are building the underlying infrastructure to support this digital transformation, alongside intuitively designed and intelligent workflow applications that can be used across the industry."
      />
      <PageHeader
        header="Transforming the aviation industry"
        subHeader="We are working hard to digitise the aviation industry providing new levels of efficiency and insight to everyone involved in the operation and maintenance of aircraft. We are building the underlying infrastructure to support this digital transformation, alongside intuitively designed and intelligent workflow applications that can be used across the industry."
        link="/careers"
        linkText="Join our Team"
      />
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRowMedium}`}
        >
          <Col lg={10} md={12}>
            {props.data.aboutUsMarkdown.edges.map((edge) => (
              <Row className={pageStyles.markdownCard}>
                <Col md={5} className={pageStyles.markdownIcon}>
                  <ScrollAnimation
                    animateOnce
                    animateIn="fadeIn"
                    offset={0}
                    delay={150}
                  >
                    <img
                      src={edge.node.frontmatter.icon.publicURL}
                      alt="icon"
                      className={styles.markdownicon}
                    />
                  </ScrollAnimation>
                </Col>
                <Col md={7}>
                  <ScrollAnimation animateOnce animateIn="fadeIn" offset={0}>
                    <Title>{edge.node.frontmatter.title}</Title>
                    <Text
                      dangerouslySetInnerHTML={{ __html: edge.node.html }}
                    />
                  </ScrollAnimation>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRowMedium} ${styles.newStandards}`}
        >
          <Col lg={10} md={12}>
            <Row>
              <Col md={{ span: 6, order: 1 }} xs={{ span: 12, order: 12 }}>
                <ModalVideo
                  channel="vimeo"
                  isOpen={isOpen}
                  videoId="330066263"
                  onClose={() => setIsOpen(false)}
                />
                <ScrollAnimation animateOnce animateIn="fadeIn" offset={0}>
                  <div className={styles.videoImageWrapper}>
                    <div className={styles.playIcon}>
                      <div className={styles.bigPictureLink}>
                        <button
                          onClick={() => setIsOpen(true)}
                          className={styles.buttonPlay}
                        >
                          <img src={playIcon} alt="playIcon" />
                        </button>
                      </div>
                    </div>
                    <Img
                      fluid={videoImage.childImageSharp.fluid}
                      className={styles.imageWithShadow}
                    />
                  </div>
                </ScrollAnimation>
              </Col>
              <Col
                md={{ span: 5, order: 12, offset: 1 }}
                xs={{ span: 12, order: 1 }}
              >
                <ScrollAnimation animateOnce animateIn="fadeIn" offset={0}>
                  <Title fontSize="22">
                    {ourVisionMarkdown.edges[0].node.frontmatter.title}
                  </Title>
                  <Text
                    padding="10px 0"
                    dangerouslySetInnerHTML={{
                      __html: ourVisionMarkdown.edges[0].node.html,
                    }}
                  />
                  <Title fontSize="22">
                    {ourRoleMarkdown.edges[0].node.frontmatter.title}
                  </Title>
                  <Text
                    padding="10px 0"
                    dangerouslySetInnerHTML={{
                      __html: ourRoleMarkdown.edges[0].node.html,
                    }}
                  />
                </ScrollAnimation>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <AboutGrid />
      <GetInTouchBar />
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col xl={10} lg={11} md={12}>
            <WeBelieve />
          </Col>
        </Row>
      </Container>
      {/* <Achievements /> */}
      <Container>
        <Started
          title="Join the Team"
          text="Join the TrustFlight team and help us reshape the aviation industry!"
          buttonText="Looking for a new challenge?"
          buttonTextStrong="View available positions"
          link="/careers"
        />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query About {
    videoImage: file(relativePath: { eq: "About/tf-video-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 526) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ourTechnologyMarkdown: allMarkdownRemark(
      filter: {
        frontmatter: {
          title: { in: ["Building the new standards in aviation records"] }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            icon {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
          html
        }
      }
    }
    aboutUsMarkdown: allMarkdownRemark(
      filter: { frontmatter: { title: { in: ["About Us"] } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            icon {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
          html
        }
      }
    }
    ourVisionMarkdown: allMarkdownRemark(
      filter: { frontmatter: { title: { eq: "Our vision is:" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
          }
        }
      }
    }
    ourRoleMarkdown: allMarkdownRemark(
      filter: { frontmatter: { title: { eq: "Our mission is:" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
          }
        }
      }
    }
    allFile(filter: { name: { regex: "/about-feature/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
